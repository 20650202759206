/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { SectionTitle } from "./common/SectionTitle";
// @ts-ignore
import Pulse from "react-reveal/Pulse";
import { useQuery } from "react-query";

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FAQ() {
  const { data } = useQuery("faq", () =>
    fetch("/api/faq").then((res) => res.json())
  );

  return (
    <section className="anchor-faq">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
        <SectionTitle isCentered>Frequently asked questions</SectionTitle>

        <Pulse>
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {(data ?? []).map((faq: { q: string; a: string }) => (
                <Disclosure as="div" key={faq.q} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-4xl">
                        <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                          <span className="text-sm text-gray-300 md:text-xl">
                            {faq.q}
                          </span>
                          <span className="flex items-center ml-6 h-7">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="pr-12 mt-2">
                        <p className="py-8 text-sm antialiased leading-7 text-gray-500 md:text-xl">
                          {faq.a.split("\n").reduce(function (arr, line) {
                            // @ts-ignore
                            return arr.concat(line, <br />);
                          }, [])}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </Pulse>
      </div>
    </section>
  );
}
